export const countries = [
    {
      image: "../../assets/1.jpg",
      title: "Brazil",
    },
    {
      image: "../../assets/1.jpg",
      title: "China",
    },
    {
      image: "../../assets/1.jpg",
      title: "France",
    }
]